'use client';
import Image from 'next/image';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { Button } from '@/components';

import * as S from './styles';

interface EmptyResourceProps {
  emptyImage: string;
  title: string;
  description: string;
  labelButton?: string;
  redirect?: () => void;
  isClean?: boolean;
}

export function EmptyResource({
  emptyImage,
  title,
  description,
  isClean,
  redirect,
  labelButton = 'Voltar para dashboard',
}: EmptyResourceProps) {
  return (
    <S.EmptyResourceRoot $clean={isClean}>
      <S.EmptyResourceContainer>
        <S.EmptyResourceImageContainer>
          <Image
            src={emptyImage}
            alt="Nenhuma informação encontrada"
            width={250}
            height={150}
          />
        </S.EmptyResourceImageContainer>
        <S.EmptyResourceContent>
          <S.EmptyResourceTitle>{title}</S.EmptyResourceTitle>
          <S.EmptyResourceDescription>{description}</S.EmptyResourceDescription>
        </S.EmptyResourceContent>
        {redirect && (
          <Button
            type={'button'}
            onClick={redirect}
            color="white"
            icon={<ArrowBackIcon />}
            iconPosition="left">
            {labelButton}
          </Button>
        )}
      </S.EmptyResourceContainer>
    </S.EmptyResourceRoot>
  );
}

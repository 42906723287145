import styled, { css } from 'styled-components';

export const HeaderContainer = styled.div`
  ${({ theme }) => css`
    background: ${theme.colors.headerGradient};
    height: 100px;
  `}
`;

export const HeaderNotFound = styled.section`
  max-width: 1024px;
  margin: auto;
  width: 100%;
  height: calc(
    100vh - 164px - 50px
  ); // 164px = HeaderContainer + 50px = FooterContainer
  position: relative;
`;
export const HeaderNotFoundContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: ${theme.spacing['08']};
    flex: 1;
    width: 100%;
    position: absolute;
    top: -100px;

    ${theme.breakpoints.tablet720} {
      flex-direction: row;
      padding: ${theme.spacing['16']};
    }
  `}
`;

'use client';

import { useRouter } from 'next/navigation';

import { Footer } from '@/components/';
import { Header } from '@/components/header';

import { EmptyResource } from '../empty-resource';
import * as S from './styles';

export const Error404 = () => {
  const router = useRouter();

  return (
    <main>
      <Header
        isHomeOrganization={false}
        isHomeProject={false}
        projectList={[]}
        isError={true}
      />
      <S.HeaderContainer />
      <S.HeaderNotFound>
        <S.HeaderNotFoundContainer>
          <EmptyResource
            redirect={() => router.back()}
            emptyImage="/assets/images/404-page.png"
            title="Oops, parece que a página que você tentou acessar não existe"
            description="Você pode voltar para a página anterior clicando no botão abaixo"
          />
        </S.HeaderNotFoundContainer>
      </S.HeaderNotFound>
      <Footer />
    </main>
  );
};

import theme from '@/utils/themes/assiny';
import styled, { css } from 'styled-components';

export const EmptyResourceRoot = styled.div<{ $clean?: boolean }>`
  ${({ theme, $clean }) => css`
    margin: auto;
    width: ${$clean ? '100%' : '90vw'};
    height: ${$clean ? '100%' : 'calc(90vh - 112px)'};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: ${theme.colors.white};
    border: 2px solid ${$clean ? 'transparent' : theme.colors.gray['200']};
    border-radius: ${theme.radius.sm};
    box-shadow: ${$clean ? 'none' : theme.shadows.lg};
  `}
`;

export const EmptyResourceContainer = styled.div`
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${theme.breakpoints.tablet720} {
    width: 520px;
  }
`;

export const EmptyResourceImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const EmptyResourceContent = styled.section`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing['16']};
  `}
`;

export const EmptyResourceTitle = styled.h2`
  ${({ theme }) => css`
    text-align: center;
    color: ${theme.colors.gray['900']};
    font-size: ${theme.typography.fontSize['20'].size};
    font-weight: ${theme.typography.fontWeight.bold};
    line-height: ${theme.typography.fontSize['20'].lineHeight};
    padding: 0 ${theme.spacing['12']};

    ${theme.breakpoints.tablet720} {
      font-size: ${theme.typography.fontSize['30'].size};
      line-height: ${theme.typography.fontSize['30'].lineHeight};
    }
  `}
`;

export const EmptyResourceDescription = styled.p`
  ${({ theme }) => css`
    text-align: center;
    color: ${theme.colors.gray['700']};
    font-size: ${theme.typography.fontSize['14'].size};
    font-weight: ${theme.typography.fontWeight.regular};
    line-height: ${theme.typography.fontSize['14'].lineHeight};
    margin-bottom: ${theme.spacing['16']};
    padding: 0 ${theme.spacing['12']};

    ${theme.breakpoints.tablet720} {
      font-size: ${theme.typography.fontSize['16'].size};
      line-height: ${theme.typography.fontSize['16'].lineHeight};
    }
  `}
`;
